export const environment = {

  production: true,  

  redirectUri: 'https://building.fms.yru.ac.th/auth/callback',  

  clientId: '97',
  dummyClientSecret: 'hM80h5zUETC4HKSqAhC1bQyul1A2yjCNPW9M6d8y', 
  baseUrl : 'https://building.fms.yru.ac.th',

  logoutUrl : 'https://passport.yru.ac.th/auth/client-logout?redirect_uri=https://building.fms.yru.ac.th' ,

  dbService : "https://api.building.fms.yru.ac.th",
  
  version : '2.0.0180 - Updated : 2022-03-25 15:20'
};
