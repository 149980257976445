import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { CallbackComponent } from './callback/callback.component';
import { AuthGuard } from './guards/auth.guard';
import { LocalService } from './local.service';
import { OauthPassportService } from './oauth-passport.service';
import { OAuthService, OAuthModule } from 'angular-oauth2-oidc';
import { LoginComponent } from './login/login.component';
// import { ThaidatePipe } from 'src/pipe/thaidate.pipe';

// import { NgSelectModule } from '@ng-select/ng-select';

import { FormsModule } from '@angular/forms';
import { MeInfoComponent } from './me-info/me-info.component';
import { AboutComponent } from './about/about.component';
import { CustomPipeModule } from './Pipe/custom-pipe.module';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,    
    CallbackComponent,
    LoginComponent,
    MeInfoComponent,
    AboutComponent,

  ],
  imports: [
    
    BrowserModule
    // , CommonModule
    , HttpClientModule
    , BrowserAnimationsModule
    , FormsModule
    , RouterModule.forRoot([
    // { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },    
    { path: '', component: HomeComponent, pathMatch: 'full'},    
    { path: 'auth/callback', component: CallbackComponent },
    { path: 'login', component: LoginComponent },

    { path: 'me', component: MeInfoComponent },
    { path: 'about', component: AboutComponent },

    { path: 'setting', loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule) },
    { path: 'area', loadChildren: () => import('./modules/area/area.module').then(m => m.AreaModule) },
        
]
// , { relativeLinkResolution: 'legacy' }
)    
    // 3rd party
    , OAuthModule.forRoot()
    , ModalModule.forRoot()
    , CustomPipeModule

  ],
  exports: [ RouterModule ],
  providers: [
    OAuthService,
    OauthPassportService,
    LocalService,
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
