import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../base/app.service';
import { OauthPassportService } from '../oauth-passport.service';

@Component({
	selector: 'app-me-info',
	templateUrl: './me-info.component.html',
})
export class MeInfoComponent extends BaseComponent implements OnInit {

	constructor(
		private router: Router
		, location: Location
		, appService : AppService
		,  private oauthPassportService: OauthPassportService,
	) {
		super(location, appService, oauthPassportService);		
	}

	public user:any = null;

	ngOnInit() {
		this.oauthPassportService.getUserIdentity().subscribe( res => {
			if (res) {
				this.user = res;
				console.log(this.user);
			}
		});

	}

	public backClick(){
		window.history.back();
	}

	
	

}
