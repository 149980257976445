import { Component, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from './base/app.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OauthPassportService } from './oauth-passport.service';
import { BaseComponent } from './base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent{
  title = 'app';

  constructor(
    private location : Location ,
    public appService : AppService,
    private oauthPassportService: OauthPassportService,
    private router : Router ,
  ){
    super(location, appService, oauthPassportService);
  }

  public user : any = null;

  ngOnInit() {     
    
    this.getUserIdentity().subscribe({
      next : result => {
        if (result){ this.user = result; }
      }, 
      error : error => {
        console.log({ aa : error });
      }
    });

    // this.getUserIdentity().subscribe( u => {
		// 	// console.log({ approot : u});
		// 	if (u){ this.user = u; }
		// }, error => {
		// 	console.log({ aa : error });
		// });

  }

  ngAfterViewInit() {    
  }

  

  public logOut(): void {
    super.logOut();
  }

  public homeClick(): void {
    this.router.navigate(['/']);
  }

  public registerClick(): void {
    this.router.navigate(['admin/registers']);
  }

  public reportClick(): void {
    this.router.navigate(['admin/report']);
  }

  public meClick() : void {
    this.router.navigate(['me']);
  }

  public aboutClick() : void {
    this.router.navigate(['about']);
  }
  
}
