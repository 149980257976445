import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: 'https://passport.yru.ac.th',

  tokenEndpoint: 'https://passport.yru.ac.th/oauth/token',
  userinfoEndpoint: 'https://passport.yru.ac.th/api/identity/v2/userinfo',

  // URL of the SPA to redirect the user to after login  

  // // localhost
  // redirectUri: 'http://localhost:4200/callback',
  // clientId: '27',
  // dummyClientSecret: 'LIQa1Ub0NlQR3hxlZzzXpUztrCW6VprXygpqMSyM', 

  // production
  redirectUri: 'https://building.fms.yru.ac.th/auth/callback',  
  // redirectUri: 'https://erp.yru.ac.th/payment/callback',  
  clientId: '97',
  dummyClientSecret: 'hM80h5zUETC4HKSqAhC1bQyul1A2yjCNPW9M6d8y', 
  

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  scope: '*',

  showDebugInformation: true,
  skipIssuerCheck: true,
  requireHttps: true,
  responseType: 'code',
  oidc: false,

  sessionChecksEnabled: false
};
