import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {OauthPassportService} from '../oauth-passport.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(private oauthPassportService: OauthPassportService ,
    private router: Router,
    private userService : UserService ,
    ) {
    // console.log('CallbackComponent.constructor');
  }

  ngOnInit() {

    console.log( 'tryLogin' );

    this.oauthPassportService.tryLogin().subscribe(async passportInfo => {
      console.log({ loginCompleted : passportInfo });

      let item = null;
      try {        

        item = await lastValueFrom(this.userService.getByStaffId(passportInfo.passport.type_id));        

      } catch(e) {
        console.log({  getUserError : e });
        // error 404 not found
      }

      console.log({ existsUser : item });
      if (!item) {
        let inserted = await lastValueFrom(this.userService.create({ 
          name: passportInfo.passport.name, 
          email: passportInfo.passport.email, 
          ref_id : passportInfo.passport.type_id ,
          user_type : passportInfo.passport.type ,
        }));

        console.log({ existsUser : 'added', inserted : inserted });
      }      

      if (passportInfo) {
        setTimeout(() => {
          // window.location.reload();
          this.router.navigate(['/']);
        }, 500);
      }
    });
  }

}
