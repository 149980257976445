import { Component, OnInit, ɵɵsetComponentScope } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../base/app.service';
import { OauthPassportService } from '../oauth-passport.service';
import { MessageService } from '../services/message.service';
import { lastValueFrom } from 'rxjs';

import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AreaService } from '../services/area.service';

import { AreaTypeService } from '../services/area-type.service';
import { BookingService } from '../services/booking.service';
import { FileUploadService } from '../services/file-upload.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
})
export class HomeComponent extends BaseComponent implements OnInit {

	constructor(
		private router: Router
		, private location: Location
		, public appService : AppService
		, private oauthPassportService: OauthPassportService

		, private modalService: BsModalService

		, private areaService : AreaService 
		, private areaTypeService : AreaTypeService
		, private bookingService : BookingService 
		, private uploadService : FileUploadService ,

	) {
		super(location, appService, oauthPassportService);		
	}

	modalRef?: BsModalRef;

	public user:any = null;
	public messageRef :any = null;
	public searchText:string = '';
	public selectedItem:any = null;

	public start : number = 0;
	public limit : number = 9;

	public areaItems : any = null;
	public areaType :any = null;

	public isProcess:boolean = false;

	public approver : boolean = false;
	public auditor : boolean = false;

	public pendingApprove : any = null;
	public pendingAudit : any = null;

	public promotePhoto:any = null;
	public promoteLink:any = null;

	public pandingFeedbacks:any = null;

	public selectAreaTypeId:number = 0;

	async ngOnInit() {
		
		this.uploadService.getPromotePhoto().subscribe( a=> {
			this.promotePhoto = a;
		});

		this.uploadService.getPromoteLink().subscribe( a=> {
			this.promoteLink = a;
		});
		
		this.oauthPassportService.getUserIdentity().subscribe({
			next : async u => {
				console.log({ user : u });
				if (u) {
					this.user = u;

					this.approver = this.isApprover(this.user)
					this.auditor = this.isAuditor(this.user)

					if (this.approver) {
						this.isProcess = true;

						this.bookingService.gets({ 
							start: 0, limit: 1000 ,
							status : 2
						}).subscribe({
							next : result => {
								this.pendingApprove = result;
								console.log({ pendingApprove : this.pendingApprove })
							} ,
							complete : () => {
								this.isProcess = false;
							}
						});							
					}

					if (this.auditor) {
						this.isProcess = true;
						
						this.bookingService.gets({ 
							start: 0, limit: 1000 ,
							status : 1
						}).subscribe({
							next : result => {
								this.pendingAudit = result;
								console.log({ pendingAudit : this.pendingAudit })
							} ,
							complete : () => {
								this.isProcess = false;
							}
						});


						// เพื่อหารายหารที่ต้องทำการประเมิน
						this.bookingService.gets({ 
							start: 0, limit: 1000 ,
							email : this.user.passport.email ,
							status : 3
						}).subscribe({
							next : result => {
								if (result && result.data) {
									
									let current:Date = new Date();
									
									this.pandingFeedbacks = result.data.filter((element : any) => {

										let isPending = false;

										if (element.feedbacks && element.feedbacks.length > 0) {
											isPending = true;												
											let booking:Date = new Date(element.booking_date);
											// ราการที่รอ ต้องเลยวันที่ปัจจุบันไปแล้ว
											if (current.getTime() < booking.getTime()) {
												isPending = false;
											}
										}

										return isPending;

										// return !(element.feedbacks && element.feedbacks.length > 0);



									})
								}
								console.log({ pandingFeedbacks : this.pandingFeedbacks, result : result });
							} ,
							complete : () => {
								this.isProcess = false;
							}
						});
					}
					
					

					let params = {
						search : '' ,
						start : 0, limit : 20
					}

					this.areaTypeService.gets({ start :0, limit: 1000 }).subscribe({
						next : result => {							
							this.areaType = result;
						}
					})
					
					// this.loadItems(this.searchText, this.start, this.limit);
				}				
			}, error : error => {
				console.log(error);
			}
		});

		this.areaItems = await lastValueFrom(this.areaService.gets({ status: 2, start: this.start, limit :this.limit }));
		console.log({ areaItems : this.areaItems })
	}

	public loginClick() {
		this.router.navigate(['/login']);
	}

	public areaInfoClick(element:any) {
		this.router.navigate(['/area/info', element.id]);
	}

	public async searchClick() {

		console.log(
			{ 
				status: 2, start: this.start, limit :this.limit ,
				type : Number(this.selectAreaTypeId) ,
				search: this.searchText 
			}
		);

		this.isProcess = true;

		this.areaItems = await lastValueFrom(this.areaService.gets({ 
			status: 2, 
			start: this.start, limit :this.limit ,
			type : Number(this.selectAreaTypeId) ,
			search: this.searchText 
		}));

		console.log(this.areaItems);

		this.isProcess = false;

	}
	

	public openMessageDialog(item:any, template: TemplateRef<any>) {
		this.selectedItem = item;
		// console.log(this.selectedItem);
		this.modalRef = this.modalService.show(
			template
			, Object.assign({}, { class: 'modal-lg' })
			);
	}

	public pageEventChange(start:number = 0, limit:number = 10) {

		this.isProcess = true;

		console.log({ start : start , limit : limit });

		// TODO : get all,
		this.areaService.gets( { start : start , limit : limit, status : 2, type : this.selectAreaTypeId }).subscribe({			

			next :  result => {
				this.areaItems = result;
	  
				console.log(this.areaItems.data);		  
				this.isProcess = false;
			  } ,
			error : refError => {
				console.log(refError);
				this.isProcess = false;
			  }
		})
		
	}	

	
	

}
