import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DbService } from '../base/db.service';
import { AppService } from '../base/app.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends DbService {
//   private baseUrl = environment.dbService;

//   constructor(private http: HttpClient) { }

  constructor(private h: HttpClient, a:AppService) {
    super(h, a);
    }   

  public upload(file: File, areaid:number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', this.getServiceURL() + '/api/upload/' + areaid, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.h.request(req);
  }

  public bookingfile(file: File, areaid:number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', this.getServiceURL() + '/api/upload/booking/' + areaid, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.h.request(req);
  }

  public promotePhoto(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', this.getServiceURL() + '/api/upload/promotephoto/0', formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.h.request(req);
  }

  public promotePdf(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', this.getServiceURL() + '/api/upload/promotelink/0', formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.h.request(req);
  }

  public getPromotePhoto(): Observable<any> {
    return this.h.get(this.getServiceURL() + '/api/upload/promotephoto/1');
  }

  public getPromoteLink(): Observable<any> {
    return this.h.get(this.getServiceURL() + '/api/upload/promotelink/1');
  }


  public delete(id:number) : Observable<any>{
		
    let token: string = this.getToken();
    let headers = new HttpHeaders({ Authorization : "Bearer " + token });
        
    return this.httpClient.delete(
        this.getServiceURL() + '/api/upload/' + id.toString() ,  { headers }
        // this.getServiceURL() + '/api/upload/' + id.toString() ,  { headers }
    );
  }

  public deletePromotePhoto() : Observable<any>{
		
    let token: string = this.getToken();
    let headers = new HttpHeaders({ Authorization : "Bearer " + token });
        
    return this.httpClient.delete(
        this.getServiceURL() + '/api/upload/promotephoto/1' ,  { headers }
    );
  }

  public deletePromotePdf() : Observable<any>{
		
    let token: string = this.getToken();
    let headers = new HttpHeaders({ Authorization : "Bearer " + token });
        
    return this.httpClient.delete(
        this.getServiceURL() + '/api/upload/promotelink/1'  ,  { headers }
    );
  }


}