import { Injectable } from '@angular/core';
import { DbService } from '../base/db.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../base/app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AreaTypeService extends DbService  {

	private dbService : string = environment.dbService;
	
	constructor(h: HttpClient, a:AppService) {
		super(h, a);
	}

	public get(id : number) : Observable<any>{

		if (!id || id == 0) {
			throw new Error('Invalid areaId');
		}

		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		return this.httpClient.get(
			this.getServiceURL() + '/api/areatype/' + id.toString(), { headers }
		);
	}

	public gets(params : { search? : string, start : number, limit : number }) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.get(
			this.getServiceURL() + '/api/areatype' + this.getQueryString(params), { headers }
		);
	}


	public create(params: { name:string }) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.post(
			this.getServiceURL() + '/api/areatype' , params,  { headers }
		);
	}

	public update(params: { id:number ,name:string }) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.put(
			this.getServiceURL() + '/api/areatype' , params,  { headers }
		);
	}


	public delete(id:number) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.delete(
			this.getServiceURL() + '/api/areatype/' + id.toString() ,  { headers }
		);
	}




}
