import {Injectable, ɵɵsetComponentScope} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {authConfig} from './oauth.config';
import {LocalService} from './local.service';
import { environment } from 'src/environments/environment';
import { ControlContainer } from '@angular/forms';
import { DbService } from './base/db.service';
import { UserService } from './services/user.service';

@Injectable()
export class OauthPassportService {

  constructor(private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private localService: LocalService,
              private userService : UserService ,
  ) {
    
    authConfig.redirectUri = environment.redirectUri;
    authConfig.clientId = environment.clientId;
    authConfig.dummyClientSecret = environment.dummyClientSecret;  

  }

  private userInfo : any = null;

  
  private subjectUser : BehaviorSubject <any> = new BehaviorSubject(null);

  

  initImplicitFlow() {
    const params = {
      redirect_uri: authConfig.redirectUri,
    };
    const url = `${authConfig.issuer}/oauth/authorize?client_id=${authConfig.clientId}&redirect_uri=${params.redirect_uri}&response_type=code&scope=${authConfig.scope}`;
    window.open(url, '_self');

  }

  private authorizationCodesToAccessTokens(code): Observable<any> {
    const params = {
      grant_type: 'authorization_code',
      client_id: authConfig.clientId,
      client_secret: authConfig.dummyClientSecret,
      redirect_uri: authConfig.redirectUri,
      code
    };

    console.log(params);

    return this.http.post(authConfig.tokenEndpoint, params);
  }

  private loadUserInfo(token) : Observable<any>
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(authConfig.userinfoEndpoint, httpOptions);
  }


  tryLogin() : Observable<any>
  {
    return new Observable<any>( ob => {
      

      this.route.queryParams.subscribe({
        next : (params:any) => {
          const code = params.code;

          console.log({code : code })
          if (code) {
  
            
  
            this.authorizationCodesToAccessTokens(code).subscribe(resp => {
              
              console.log({ authorizationCodesToAccessTokens : resp });
              this.localService.set('auth_key', resp);
  
              this.loadUserInfo(resp.access_token).subscribe(info => {
  
  
                // if (info.staff) { 
                //   this.userService.getByStaffId(info.staff.staffid).subscribe({
                //     next : staff => {
                //       // account คือข้อมูลผู้ใช้งานจาก tb_user เพื่อเก็บรายละเอียดต่างๆ และอาจมาการเก็บอื่นๆเพิ่มเติมในอนาคต
                //       info.account = staff;
                      
                //       this.localService.set('userinfo', info);
                //       // this.subjectUser.next(info);
    
                //       ob.next(info);    
                //       ob.complete();          
  
                //     }, error : getUserError => {
                      
                //       console.log({ getUserError : getUserError });
  
                //       // ดึงข้อมูล user จากฐานไม่เจอ จะได้ 404 ทำให้ต้องมาดักตรงนี้
                //       this.localService.set('userinfo', info);
                //       this.subjectUser.next(info);
  
                //       ob.next(info);    
                //       ob.complete();          
                //     }
  
                //   });
  
                // } else {                
  
                  // ถ้าข้อมูลจาก passport บอกว่าไม่ใช่ staff ก็ทำการส่งต่อไปได้เลย
                  this.localService.set('userinfo', info);
                  this.subjectUser.next(info);
                  
                  ob.next(info);    
                  ob.complete();          
                // }
  
              }, error => {
                ob.error(error);
              });
            }, error => {
  
              console.log({ code : 'ok'})
  
  
              console.log(error);
              ob.error(error);
            });
          }
        } ,
        error : e => {
          console.log(e);
          ob.error(e);
        } ,
        complete : () => {

        }
      });

      // this.route.queryParams.subscribe(params => {        
      //   const code = params.code;

      //   console.log({code : code })
      //   if (code) {

          

      //     this.authorizationCodesToAccessTokens(code).subscribe(resp => {
            
      //       console.log({ authorizationCodesToAccessTokens : resp });
      //       this.localService.set('auth_key', resp);

      //       this.loadUserInfo(resp.access_token).subscribe(info => {


      //         // if (info.staff) { 
      //         //   this.userService.getByStaffId(info.staff.staffid).subscribe({
      //         //     next : staff => {
      //         //       // account คือข้อมูลผู้ใช้งานจาก tb_user เพื่อเก็บรายละเอียดต่างๆ และอาจมาการเก็บอื่นๆเพิ่มเติมในอนาคต
      //         //       info.account = staff;
                    
      //         //       this.localService.set('userinfo', info);
      //         //       // this.subjectUser.next(info);
  
      //         //       ob.next(info);    
      //         //       ob.complete();          

      //         //     }, error : getUserError => {
                    
      //         //       console.log({ getUserError : getUserError });

      //         //       // ดึงข้อมูล user จากฐานไม่เจอ จะได้ 404 ทำให้ต้องมาดักตรงนี้
      //         //       this.localService.set('userinfo', info);
      //         //       this.subjectUser.next(info);

      //         //       ob.next(info);    
      //         //       ob.complete();          
      //         //     }

      //         //   });

      //         // } else {                

      //           // ถ้าข้อมูลจาก passport บอกว่าไม่ใช่ staff ก็ทำการส่งต่อไปได้เลย
      //           this.localService.set('userinfo', info);
      //           this.subjectUser.next(info);
                
      //           ob.next(info);    
      //           ob.complete();          
      //         // }

      //       }, error => {
      //         ob.error(error);
      //       });
      //     }, error => {

      //       console.log({ code : 'ok'})


      //       console.log(error);
      //       ob.error(error);
      //     });
      //   }
      // }, error => {
      //   console.log(error);
      //   ob.error(error);
      // });
    });

    // this.route.queryParams.subscribe(params => {
    //   const code = params.code;
    //   if (code) {
    //     this.authorizationCodesToAccessTokens(code).subscribe(resp => {
    //       // @ts-ignore
    //       this.localService.set('auth_key', resp);
    //       this.loadUserInfo(resp.access_token).subscribe(info => {
    //         this.localService.set('userinfo', resp);
    //       });          
    //       // setTimeout(() => {
    //       //   this.router.navigate(['']);
    //       // }, 500);
    //     });
    //   }
    // });
  }
 

  refreshToken() {
    // coming soon...
  }

  isAuthenticated() {
    return this.localService.exist('auth_key');
  }

  getAccessToken() {
    return this.localService.get('auth_key').access_token;
  }

  getRefreshToken() {
    return this.localService.get('auth_key').refresh_token;
  }

  getUserIdentity() : Observable<any>
  {    
    // console.log(this.subjectUser.getValue());
    if (this.localService.exist('userinfo')) {
      let userInfo =  this.localService.get('userinfo');
      if (this.subjectUser.getValue() != userInfo) {
        // console.log(userInfo);

        if (userInfo.staff) {
          this.userService.getByStaffId(userInfo.staff.staffid).subscribe(staff => {
            userInfo.account = staff;
            this.subjectUser.next(userInfo);
          });          
        } else {

          this.subjectUser.next(userInfo);
        }

        
        
      }
    }  
    
    return this.subjectUser.asObservable();    
  }

  logOut() {
    this.localService.destroy('userinfo');
    this.localService.destroy('auth_key');

    // window.localStorage.removeItem('userinfo');
    // window.localStorage.removeItem('auth_key');
  }
}
