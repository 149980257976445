

<h2>            
    ระบบขอใช้ห้องและอาคารสถานที่
    <div class="small fw-normal">
        E-Building FMS - คณะวิทยาการจัดการ มหาวิทยาลัยราชภัฏยะลา
    </div>
</h2>


<div class="bg-white border mt-4 p-5 shadow">
    <div class="row  pb-5 ">
        <div class="col-12 fs-5" >
            <p class="fw-bolder">ระบบขอใช้ห้องและอาคารสถานที่</p>
            <span class="fw-light">
                เป็นระบบที่พัฒนาขึ้นมาเพื่อช่วยอำนวยความสะดวกให้กับบุคลากร และนักศึกษา 
                <br>
                ที่ต้องการจองใช้งานห้อง หรืออาคารสถานที่ต่างๆ ของคณะวิทยาการจัดการ
                <br>
                อีกทั้งยังช่วยในการบริหารจัดการ ตรวจสอบ อนุมัติ อย่างเป็นระบบ 
                <br>
                สามารถตรวจสอบสถานะการดำเนินการได้        
            </span>
            
        </div>    
    </div>
    
    <div class="row pt-5 border-top fs-5">
        <div class="col-12">
            <div class="fw-bolder">ดูแลและพัฒนาโดย</div>
            <ul>
                <li class="pb-1 fw-light">คณะวิทยาการจัดการ มหาวิทยาลัยราชภัฏยะลา</li>
            </ul>
        </div>
    </div>
</div>

