import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
// @Injectable()
export class AppService {

  // private _template : Subject<FormTemplate> = new Subject<FormTemplate>();

  constructor() { 
    this.dbService = environment.dbService;
  }

  private title:Subject<any> = new Subject<any>();
  
  private isSpiner:Subject<string> = new Subject<string>();


  public applicationTitle : string = "ระบบจ่ายเงิน";
  public applicationName : string = "ใบสำคัญจ่าย";
  public applicationDetail : string = "ระบบจ่ายเงิน";
  public loginPath : string = "login";

  // public dbService : string = "http://localhost:8000/yru-erp-payment/api";
  public dbService : string = "https://api3333333.yru.ac.th";
  


  public VALIDATOR_NAME:string = 'นางสาวประภาพรรณ โชติสูงเนิน';
  public VALIDATOR_POSITION :string = 'หัวหน้างานการคลัง';

  public paymentMethodNames = [
		'ทั้งหมด', 'เงินสด', 'เช็ค', 'โอนเงิน', 'จ่ายตรง(GFMIS)', 'ลงบัญชีอย่างเดียว', 'จ่ายตรงโดยกรมบัญชีกลาง'
	];


  // // public departmentName : string = "มหาวิทยาลัยราชภัฏยะลา - Yala Rajabhat University";
  // // public departmentAddress : string = "133 ถนน เทศบาล 3 ตำบล สะเตง อำเภอ เมืองยะลา ยะลา 95000"

  
  // public departmentName : string = "โปรเจคงานพี่แอ บริษัท abc";
  // public departmentAddress : string = "ที่ตั้ง"




  // public setTitle(title:string, subTitle:string=""){
  //   // this.title.next({ title: title, subTitle:subTitle});
  // }

  public getTitle():Observable<any>{
    return this.title.asObservable();
  }

  public showSpiner(message : string = "กำลังประมวลผล .."):void{
    this.isSpiner.next(message);
  }

  public hideSpiner(message : string = ""):void{
    this.isSpiner.next(null);
  }

  public getSpiner(): Observable<string>{
    return this.isSpiner.asObservable();
  }

  public toThaiDateString(d : Date) : string
  {    
    // console.log(d);
    if (d){
      let y:number = d.getFullYear();
      if (y < 2200) y += 543;

      return d.getDate().toString().padStart(2, '0')
        + '/' + (d.getMonth() +1).toString().padStart(2, '0')
        + '/' + y.toString();
    }
    return "";
  }

  // thaiDateString = "dd/mm/yyyy"
  public toGlobalDateString(thaiDateString:string) : string {
    let result = "";
    // replace / with -
    if (thaiDateString.length == 10){
      let arr = thaiDateString.split('/');
      if (arr.length == 3){
        let year = Number.parseInt(arr[2]);
        if (year > 2200) year = year - 543;

        result = year.toString() + "-" + arr[1] + "-" + arr[0];
      }      
    }

    return result;
  }

  public toCurrencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }


  public dayThai(d:number) : string {
		let dayNames = [
			'อาทิตย์',
			'จันทร์',
			'อังคาร',
			'พุธ',
			'พฤหัส',
			'ศุกร์',
			'เสาร์',			
		]
		return dayNames[d];
	}

	public dayThaiShort(d:number) : string {
		let dayNames = [
			'อา',
			'จ',
			'อ',
			'พ',
			'พฤ',
			'ศ',
			'ส',
		]
		return dayNames[d];
	}

	public monthThaiShort(month:number) :string {

        let months = [
            "ม.ค.",
            "ก.พ.",
            "มี.ค.",
            "เม.ย.",
            "พ.ค.",
            "มิ.ย.", 
            "ก.ค.",
            "ส.ค.",
            "ก.ย.",
            "ต.ค.",
            "พ.ย.",
            "ธ.ค."
        ]
        return months[month];
    }

    public monthThai(month:number) :string {

        let months = [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน", 
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม"
        ]
        return months[month];
    }

    public yearThai(year:number) : string {
        return (year + 543).toString();
    }
    

}
