import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route} from '@angular/router';
import {Observable} from 'rxjs';
import {OauthPassportService} from '../oauth-passport.service';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private oauthPassportService: OauthPassportService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.oauthPassportService.isAuthenticated()) {
      return true;
    }

    // navigate to login page
    this.router.navigate(['/login']);
    return false;
  }

}
