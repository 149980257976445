// import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

export abstract class BaseService  {

	private tokenName : string = "auth_token";

	constructor(		
		private baseServiceUrl:string
	) { 
		// console.log({ baseServiceUrl : baseServiceUrl })
	}	

	public getServiceURL() : string {				
		return this.baseServiceUrl;
	}

	public getQueryString(conditions: any) 
	{
		let paramsUrl: string = "";

		if (conditions != null) {
			let params = Object.keys(conditions);
			for (let param of params) {

				if (conditions[param] != null) {
					if (paramsUrl == "") {
						paramsUrl += "?";
					} else {
						paramsUrl += "&";
					}
					let paramValue: string = encodeURIComponent(conditions[param]);
					paramsUrl += param + "=" + paramValue;
				}
			}
		}
		return paramsUrl;
	}

	public setToken(token : string) : void {
		localStorage.setItem(this.tokenName, token);
	}

	public getToken() : string {
		let token = localStorage.getItem(this.tokenName);
		if (token != null)
			return token;
		
		return "";
	}

	public getTokenName() : string{
		return this.tokenName;
	}


}
