<div class="container mt-2">
  <section class="jumbotron text-center">
    <div class="container">
      <h3>Login with YRU Passport</h3>
      <p>
        <!-- <button class="btn btn-primary" (click)="loginWithOutLib()">
          Login with out use lib.
        </button>
        &nbsp;
        <button class="btn btn-secondary" (click)="login()">
          Login with use lib.
        </button> -->
      </p>
    </div>
  </section>
</div>

