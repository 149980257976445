import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class LocalService {

  exist(key): boolean {
    return typeof window.localStorage[key] !== 'undefined';
  }

  get(key) {
    const decrypted = CryptoJS.AES.decrypt(window.localStorage[key], 'YRU');
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }

  set(key, value) {
    window.localStorage[key] = CryptoJS.AES.encrypt(JSON.stringify(value), 'YRU');
  }

  destroy(key) {
    window.localStorage.removeItem(key);
  }

}
