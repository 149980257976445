import { Injectable } from '@angular/core';
import { DbService } from '../base/db.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../base/app.service';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService extends DbService  {

	private dbService : string = environment.dbService;
	
	constructor(h: HttpClient, a:AppService) {
		super(h, a);
	}

	
	public getByStaffId(id : number) : Observable<any>{

		if (!id || id == 0) {
			throw new Error('Invalid staff');
		}

		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		

		return this.httpClient.get(
			this.getServiceURL() + '/api/user/' + id, { headers }
		);
	}

	

	public create(item:any) : Observable<any> {
		if (item) {
			let token: string = this.getToken();
			let headers = new HttpHeaders({ Authorization : "Bearer " + token });
						
			return this.httpClient.post(
				this.getServiceURL() + '/api/user', item, { headers }
			);
		}

		return null;
	}

	
}
