
<h2>
    {{ user.passport.name }}
    <div class="small">
        ข้อมูลผู้ใช้งานระบบ
    </div>
</h2>

<div class="row mt-3 g-3 border-top111">

    <table class="table fs-5sss table-light table-striped">

        <tbody>
            <tr>
                <td class="text-end text-secondary fw-light">ชื่อ :</td>
                <td>{{ user.passport.name }}</td>
            </tr>
            <tr>
                <td class="text-end text-secondary fw-light">ประเภท :</td>
                <td>{{ user.passport.type }}</td>
            </tr>            
            <tr>
                <td class="text-end text-secondary fw-light">ตำแหน่ง :</td>
                <td>
                    <div *ngIf="user.staff">
                        {{ user.staff.staffpositionname }}/{{ user.staff.stafftypename }}
                    </div>
                    <div *ngIf="user.staff" class="fw-light">
                        {{ user.staff.masterdepartmentname }}
                    </div>
                    
                </td>
            </tr>
            <tr>
                <td class="text-end text-secondary fw-light">ที่อยู่อีเมล์ :</td>
                <td>{{ user.passport.email }}</td>
            </tr>
            <tr>
                <td class="text-end text-secondary fw-light">ชื่อเข้าใช้งานระบบ :</td>
                <td>{{ user.passport.username }}</td>
            </tr>
        </tbody>
    </table>
    
    <!-- <div class="text-center">
        <button class="btn btn-light" (click)="backClick()">ปิด</button>
    </div> -->

</div>
