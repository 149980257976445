import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { AppService } from './app.service';


export abstract class DbService extends BaseService  {
	
	protected _user : Subject<any> = new Subject<any>();	

	constructor(
		protected httpClient: HttpClient
		, private dbAppService: AppService
	) { 
		super (dbAppService.dbService);
	}
	

	// public get(id: number): Observable<any> {
	// 	let token: string = this.getToken();
	// 	let headers = new HttpHeaders({ Authorization : "Bearer " + token });

	// 	return this.httpClient.get(
	// 		this.getApiURL() + "/" + id.toString()
	// 		, { headers }
	// 	);
	// }

	// public gets(params: any): Observable<any> {

	// 	let token: string = this.getToken();
	// 	let headers = new HttpHeaders({ Authorization : "Bearer " + token });

	// 	return this.httpClient.get(
	// 		this.getApiURL() + this.getQueryString(params), { headers }
	// 	);
	// }

	// public insert(item: any): Observable<any> {
	// 	console.log({ service_insert : item });
	// 	let token: string = this.getToken();
	// 	let headers = new HttpHeaders({ Authorization : "Bearer " + token });

	// 	return this.httpClient.post(this.getApiURL(), item, { headers });
	// }

	// public update(item: any): Observable<any> {
	// 	let token: string = this.getToken();
	// 	let headers = new HttpHeaders({ Authorization : "Bearer " + token });

	// 	return this.httpClient.put(this.getApiURL(), item, { headers });
	// }

	// public delete(id: number): Observable<any> {
	// 	let token: string = this.getToken();
	// 	let headers = new HttpHeaders({ Authorization : "Bearer " + token });

	// 	return this.httpClient.delete(
	// 		this.getApiURL() + "/" + id.toString(), { headers }
	// 	);
	// }

	public formatResult(data : any[], totalCount:number, start:number , limit : number, params : any = null) : any {

		start = Number(start);
		limit = Number(limit);

		const result : any = {
			data : data ,
			metadata : {
				count : totalCount ,	
				// where : filters,
				// conditions : params.conditions ,
				params : params ,
				currentPage : {
					start : start, limit : limit
				} ,
				nextPage : { 
					start : (start + limit < totalCount ) ? start + limit  : start , 
					limit : limit
				} ,
				previousPage : { 
					start : (start - limit < 0 ) ? 0 : start - limit , 
					limit : limit
				} ,
			}					
		}
		
		return result;
	}

}
