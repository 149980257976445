import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../base/app.service';

// import { UtilsService } from './services/utils.service';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'datethai'})
export class DateThaiPipe implements PipeTransform {

    constructor(
      private utilsService : AppService
      ) {

    }

    transform(value: Date , exponent:string = 'short'): string {

        let result : string = '';
        let dateValue: Date = new Date(value);

        if (exponent == 'short') {
            return `${ dateValue.getDate().toString().padStart(2, '0') }/${ (dateValue.getMonth() + 1).toString().padStart(2, '0') }/${ dateValue.getFullYear() + 543 }` ;
        } else if (exponent == 'medium') {
            return `${ this.utilsService.dayThaiShort(dateValue.getDay()) } ${ dateValue.getDate() } ${ this.utilsService.monthThaiShort(dateValue.getMonth() ) }${ dateValue.getFullYear() + 543 - 2500 }` ;

        } else if (exponent == 'full') {
            return `${ this.utilsService.dayThai(dateValue.getDay()) } ${ dateValue.getDate() } ${ this.utilsService.monthThai(dateValue.getMonth() ) } ${ dateValue.getFullYear() + 543 }` ;

        };

        return result;
    }
}