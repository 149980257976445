import { NgModule } from '@angular/core';
import { DateThaiPipe } from './datethai.pipe';

@NgModule({
  declarations: [
    DateThaiPipe,
  ],
  exports: [
    DateThaiPipe,
  ]
})
export class CustomPipeModule { }