<!-- <template-nav  *ngIf="template == 2; else verticalTemplate"></template-nav >
  
<ng-template #verticalTemplate>
    <router-outlet></router-outlet> 
</ng-template> -->

<!-- <template-nav [user]="user"></template-nav > -->

<div class="main-header fixed-top border-bottom shadow-sm" >
                
    <div class="container d-flex align-items-end p-0 pt-sm-2 pb-sm-2">
        <div class="flex-fill d-none d-lg-block">
            <!-- <a href="/" class="text-dark" style="font-family: 'Caveat', cursive;"> -->
            <a href="/" class="text-dark" >
                <div class="d-inline-block align-middle pe-2 pt-2">
                    <span class="material-icons" style="font-size: 40px;">
                        apartment
                    </span>
                </div>
                <div class="d-inline-block align-middle">
                    <span class="fs-2 align-middle">             
                        ระบบขอใช้ห้องและอาคารสถานที่
                    </span>  
                </div>      
            </a>
        </div>

        <nav class="navbar navbar-expand-lg p-1 p-sm-2 navbar-light flex-fill ">
            <div class="container-fluid justify-content-start">
                <a class="navbar-brand fw-bolder d-lg-none" href="/" >
                    <div class="d-inline-block align-middle pe-2 pt-2">
                        <span class="material-icons" style="font-size: 30px;">
                            apartment
                        </span>
                    </div>
                    <div class="d-inline-block align-middle">
                        <span class="fs-3 fw-bolder align-middle">             
                            E-Building FMS
                        </span>  
                    </div>     
                </a>

              <button  class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>

              <!--  -->
              <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0"> -->
                <ul class="navbar-nav mb-2 mb-lg-0">

                <li class="nav-item">
                    <a class="nav-link text-start" aria-current="page" [routerLink]="['/area/agender']" >สถานะห้อง</a>
                </li>
                <li  *ngIf="!user" class="nav-item" >
                    <a class="nav-link text-start" aria-current="page" [routerLink]="['/login']" >
                        <span>|</span>
                        เข้าสู่ระบบ
                    </a>
                </li>
                <li  *ngIf="user" class="nav-item" >
                    <a class="nav-link text-start" aria-current="page" [routerLink]="['/area/hist']" >ประวัติการใช้บริการ</a>
                </li>
                  <li class="nav-item" *ngIf="user && isAuditor(user)">
                    <a class="nav-link text-start" aria-current="page" [routerLink]="['/area/auditor']" >ตรวจสอบ</a>
                  </li>
                  <li class="nav-item" *ngIf="user && isApprover(user)">
                    <a class="nav-link text-start" aria-current="page" [routerLink]="['/area/approve']" >อนุมัติ</a>
                  </li>
                  <li class="nav-item" *ngIf="user && isAdmin(user)">
                    <a class="nav-link text-start" aria-current="page" [routerLink]="['/setting']" >ตั้งค่า/รายงาน</a>
                  </li>                  

                  <li  *ngIf="user" class="nav-item dropdown text-start">
                    <a class="nav-link " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <!-- {{ user.passport.name}} -->
                        <!-- <span class="d-inline-block d-sm-none" >
                            ตั้งค่า
                        </span> -->
                        <span class="material-icons" >
                            account_circle
                        </span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end shadow-sm small" aria-labelledby="navbarDropdown">                        
                        <!-- <li class="text-start text-secondary"><a class="dropdown-item small" [routerLink]="['/setting']" >ตั้งค่า</a></li> -->
                        <li class="text-start text-secondary"><a class="dropdown-item small" href="javascript:void(0)" (click)="meClick()" >{{ user.passport.name }}</a></li>
                        <li class="text-start text-secondary"><hr class="dropdown-divider"></li>
                        <li class="text-start text-secondary"><a class="dropdown-item small" href="javascript:void(0)" (click)="aboutClick()" >เกี่ยวกับเรา</a></li>
                        <li class="text-start text-secondary"><a class="dropdown-item small" href="javascript:void(0)" (click)="logOut()" >ออกจากระบบ</a></li>
                    </ul>
                  </li>                      
                </ul>                    
              </div>
            </div>
          </nav>

        <!-- <div *ngIf="user != null" class="google-font fs-4 flex-fill text-end"> -->
            <!-- <button class="btn text-dark fw-light" (click)="homeClick()">ประวัติการแจ้งเตือน</button>
            <button class="btn text-dark fw-light" (click)="registerClick()" >ลงทะเบียนระบบสารสนเทศ</button>
            <button class="btn text-dark fw-light" (click)="reportClick()">ค้นหารายการ</button>
            <button class="btn text-dark fw-light" (click)="logOut()">| ออกจากระบบ</button> -->
            
        <!-- </div> -->

    </div>    
      
</div>
      
<div class="container main-body pt-3 pb-5 mb-5 pt-sm-5" >    
    <!-- <div class="text-end google-font fw-light text-secondary pb-3" *ngIf="user">
        {{ user.passport.name }}
    </div>     -->
    
    <router-outlet></router-outlet>
</div>

<footer class="fixed-bottom p-3 border-top text-dark text-center" >
    <div>
        Copyright &copy; yru.ac.th | 
        คณะวิทยาการจัดการ มหาวิทยาลัยราชภัฏยะลา | v.103
    </div>
</footer>


<!-- <ng-template #verticalTemplate>
    <router-outlet></router-outlet> 
</ng-template> -->


