


<div class="row" >
    <!-- <div class="pt-3 pb-3 col-12 text-center">
        <h1>            
            ห้องและอาคารสถานที่
            <div class="pt-2 small fw-normal text-dark text-shadow">
                คณะวิทยาการจัดการ มหาวิทยาลัยราชภัฏยะลา
            </div>
        </h1>
    </div> -->
    <div *ngIf="pandingFeedbacks && pandingFeedbacks.length > 0" class="col-12 p-4 pt-0">
        <div class="m-0 p-4 shadow-sm alert alert-success text-center">

            
            <div class="fs-5">
                รายการรอการประเมินการใช้บริการ :
                <a [routerLink]="['/area/hist']" class="text-decoration-none">
                    {{ pandingFeedbacks.length }} รายการ
                </a>                        
            </div> 
            <div class="small">
                กรุณาทำการประเมินการใช้บริการ เพื่อเป็นข้อมูลในการปรับปรุงและพัฒนาบริการให้ดียิ่งขึ้น
                <!-- ก่อนใช้บริการในครั้งถัดไป -->
            </div>
            
        </div>
    </div>

    <div *ngIf="approver || auditor" class="col-12 p-4 pt-0 fs-6">
        <div class="row m-0 p-4  shadow-sm alert alert-warning">
            <!-- {{ approver }} {{ auditor }} -->
            <div *ngIf="auditor" class="col-sm-6 text-center">
                รายการรอตรวจสอบ :
                <span *ngIf="pendingAudit && pendingAudit.data && pendingAudit.count > 0">
                    <a [routerLink]="['/area/auditor']" class="text-decoration-none">
                        {{ pendingAudit.data.length }} รายการ
                    </a>                        
                </span> 
                
                <span *ngIf="!(pendingAudit && pendingAudit.data && pendingAudit.count > 0)">
                    ไม่พบรายการ
                </span>
            </div>

            <div *ngIf="approver" class="col-sm-6 text-center">
                รายการรออนุมัติ :
                
                <span *ngIf="pendingApprove && pendingApprove.data && pendingApprove.count > 0">
                    <a [routerLink]="['/area/approve']" class="text-decoration-none">
                        {{ pendingApprove.data.length }} รายการ
                    </a>                        
                </span> 
                
                <span *ngIf="!(pendingApprove && pendingApprove.data && pendingApprove.count > 0)">
                    ไม่พบรายการ
                </span>
                    
            </div>
        </div>
    </div>    

    <div class="col-12 ps-4 pe-4 mb-4">
        <div class="shadow border rounded-3">
            <img src="assets/cover.webp" alt="" class="w-100">
        </div>

        <div *ngIf="promotePhoto" class="mt-4 shadow border rounded-3">
            <img class="w-100" src="{{appService.dbService}}/api/upload/{{ promotePhoto.id}}" alt="">
        </div>

        <div *ngIf="promoteLink" class="text-center mt-3">
            <a 
                class="text-decoration-none fs-3"
                href="{{appService.dbService}}/fs/{{ promoteLink.filename}}" 
                target="_blank">เอกสารประชาสัมพันธ์</a>
        </div>
    </div>
    
</div>



<!-- <h2>
    ประวัติข้อความ
    <div class="small">
        ประวัติข้อความที่ได้รับทั้งหมด
    </div>
</h2> -->

<div class="row " *ngIf="areaItems && areaItems.data && areaType && areaType.data">
    <!-- <div class="col-12 ps-4 pe-4 text-center">
        <select *ngIf="areaType && areaType.data" name="a" id="a" class="form-control w-50">
            <option value="0">แสดงทุกประเภท</option>
            <ng-container *ngFor="let element of areaType.data">
                <option value="{{ element.id }}">{{ element.name }}</option>
            </ng-container>
        </select>
    </div> -->    

    <div class="col-12 ps-5 pe-5">

        <!-- <div class="pt-2 pb-2 bg-white opacity-75 m-0 shadow-sm11"> -->
            <div class="row alert alert-light" >
                <div class="col-sm-6">            
                    <div class="form-group">
                        <label for="">คำค้นหา</label>
                        <input type="text" class="form-control form-control-lg border border-primary" 
                            placeholder="ค้นหาห้องและอาคารสถานที่" 
                            aria-label="Recipient's username" 
                            aria-describedby="button-addon2"
                            [disabled]="isProcess"
                            [(ngModel)]="searchText"
                            maxlength="30"
                        >
                    </div>                            
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="">ประเภท</label>
                        <select class="form-control form-control-lg border border-primary" 
                            [(ngModel)]="selectAreaTypeId"
                            [disabled]="isProcess"
                        >
                            <option value="0">ไม่ระบุ</option>
                            <ng-container *ngFor="let element of areaType.data">
                                <option value="{{ element.id }}">
                                    {{ element.name }}
                                </option>
                            </ng-container>
                            
                        </select>
                    </div>                    
                </div>
                <div class="col-sm-3">
                    <label for="">&nbsp;</label>
                    <div>
                        <button class="btn btn-lg btn-primary" type="button" 
                            [disabled]="isProcess"
                            (click)="searchClick()" 
                            >
                            <span class="material-icons align-middle">
                                search
                            </span>
                            ค้นหา
                        </button>
                    </div>
                </div>                
            </div>  
        <!-- </div> -->
                      
    </div>

    <div class="col-sm-4 p-4" *ngFor="let element of areaItems.data">
        <div class="border border-1 rounded-3 p-2 shadow h-100 bg-white">

            <div class="p-0" *ngIf="element.files && element.files.length > 0">
                <a href="javascript:void(0)" (click)="areaInfoClick(element)">
                    <img class="w-100" src="{{appService.dbService}}/api/upload/{{ element.files[0].id}}" alt="">
                </a>
                <!-- <img src="/assets/p2.webp" class="w-100" alt=""> -->
            </div>

            <div *ngIf="!element.files || (element.files && element.files.length == 0)" class="p-0">
                <a href="javascript:void(0)" (click)="areaInfoClick(element)">
                    <img src="/assets/p2.webp" class="w-100" alt="">
                </a>
            </div>
            

            <div class="p-3 ps-4 pe-4">
                <div class="fs-5 pb-2">
                    <div class="fw-bold">
                        {{ element.name }}
                    </div>                    
                    <div class="small" *ngIf="element.note">
                        {{ element.note}}
                    </div>
                </div>

                <div class="pb-3">                    
                    <div *ngIf="element.location">{{ element.location }}</div>
                    <div *ngIf="element.builder || element.floor" >
                        <span class="pe-2" *ngIf="element.builder">อาคาร {{ element.builder }}</span>
                        <span *ngIf="element.floor">ชั้น {{ element.floor }}</span>
                    </div>
                    <div *ngIf="element.room">ห้อง {{ element.room }}</div>
                    
                    <div class="pt-2">
                        <span class="me-2" *ngIf="element.type">ประเภท {{ element.type.name }}</span>
                        <span *ngIf="element.max_attender">รองรับ {{ element.max_attender }} คน</span>
                    </div>
                    
                </div>

                <div>
                    <button class="btn btn-primary btn-sm" (click)="areaInfoClick(element)">
                        <span class="material-icons align-middle">
                            <!-- bookmark_add -->
                            event_note
                        </span>
                        รายละเอียด
                    </button>
                </div>
            </div>                

        </div>

        
        
    </div>

    <div class="col-12  p-4">
        <div *ngIf="areaItems && areaItems.data.length > 0" >

            <div class='small d-inline-block ps-1  title-font'>
                {{ areaItems.current_page.start + 1 }} - {{ areaItems.current_page.start + areaItems.data.length }} of {{ areaItems.count }}
            </div>

            <div class="d-inline-block ps-1">
                <ul class="pagination pagination-sm">
                    <li class="page-item {{ areaItems.current_page.start == 0 ? 'disabled' : '' }}" >
                        <a class="page-link" 
                            href="javascript:void(0)" 
                            (click)="pageEventChange(areaItems.current_page.start - areaItems.current_page.limit, areaItems.current_page.limit)" >                            
                                <span class="material-icons-outlined align-middle" style="font-size: 14px;">
                                    arrow_back_ios
                                </span>
                        </a>
                    </li>
                    <li class="page-item {{ areaItems.current_page.start + areaItems.current_page.limit >= areaItems.count ? 'disabled' : '' }}">
                        <a class="page-link" 
                            href="javascript:void(0)" 
                            (click)="pageEventChange(areaItems.current_page.start + areaItems.current_page.limit, areaItems.current_page.limit)" >
                                <span class="material-icons-outlined align-middle" style="font-size: 14px;">
                                    arrow_forward_ios
                                </span>
                        </a>
                    </li>
                </ul>
            </div>							
        </div>
    </div>
</div>



<div *ngIf="false" class="row pt-3">



    <div class="col-12 col-sm-6">
        <div>
            <label for="search" class="form-label">คำค้นหา</label>
            <div class="input-group mb-3">
                <input type="text" class="form-control" 
                    placeholder="คำที่ต้องการค้นหา" 
                    [(ngModel)]="searchText" >

                <button class="btn btn-outline-secondary" type="button" id="button-addon2"                    
                    (click)="searchClick()"
                >
                    ค้นหา
                </button>
            </div>
        </div>
    </div>

    <div class="col-12" *ngIf="messageRef && messageRef.data">
        <table class="table table-striped table-light">
            <thead>
                <tr>
                    <th>รายการ</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of messageRef.data">
                    <td>
                        <div>
                            <a href="javascript:void(0)"
                                (click)="openMessageDialog(element, messageInfoDialog)"                            
                            >
                                {{ element.subject }}
                            </a>
                        </div>
                        <div class="small fw-light">
                            <span>{{ element.created_at | date : 'dd/MM/yyy' }} {{ element.created_at | date : 'hh:mm:ss' }} </span>
                            <span *ngIf="element.app"> | {{ element.app.name }}</span>
                        </div>
                        
                    </td>                    
                </tr>                
            </tbody>
        </table>
        
    </div>


    <!-- <div class="col-12 text-end" *ngIf="messageRef && messageRef.metadata">

        <div class="d-inline-block pe-2">
            <small *ngIf="messageRef">
                {{ messageRef.metadata.currentPage.start + 1 }} - {{ messageRef.metadata.currentPage.start + messageRef.data.length }} of {{ messageRef.metadata.count }} Items
            </small>
        </div>

        <div class='d-inline-block text-end'>
        
            <ul *ngIf="messageRef" class="pagination pagination-sm">
                <li class="page-item {{ messageRef.metadata.currentPage.start == 0 ? 'disabled' : '' }}" >
                    <a class="page-link" 
                        href="javascript:void(0)" 
                        (click)="pageChange(messageRef.metadata.previousPage.start, messageRef.metadata.previousPage.limit)" >
                            <span class="material-icons">
                                arrow_back_ios
                            </span>
                    </a>
                </li>
                <li class="page-item {{ messageRef.metadata.currentPage.start + messageRef.metadata.currentPage.limit >= messageRef.metadata.count ? 'disabled' : '' }}">
                    <a class="page-link" 
                        href="javascript:void(0)" 
                        (click)="pageChange(messageRef.metadata.nextPage.start, messageRef.metadata.nextPage.limit)" >
                            <span class="material-icons">
                                arrow_forward_ios
                            </span>
                    </a>
                </li>
            </ul>
        </div>
        
    </div>     -->

</div>


<!-- Modal -->
<ng-template #messageInfoDialog>
        
    <div class="modal-header modal-lg"  >
        <h4 class="modal-title pull-left">รายละเอียด</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-light table-striped">
            <tbody>
                <tr>
                    <td>รายการ</td>
                    <td>{{ selectedItem.subject }}</td>
                </tr>
                <tr>
                    <td>เนื้อหา</td>
                    <td> 
                        <pre>{{ selectedItem.body }}</pre>
                    </td>
                </tr>
                <tr>
                    <td>ผู้ส่ง</td>
                    <td>
                        <!-- <span *ngIf="selectedItem.app"> -->
                            {{ selectedItem.app.name }}
                        <!-- </span> -->
                    </td>
                </tr>
                <tr>
                    <td>วันที่ส่ง</td>
                    <td>
                        {{ selectedItem.created_at | datethai }} {{ selectedItem.created_at | date : 'hh:mm:ss' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</ng-template>
