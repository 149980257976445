import {Component, OnInit} from '@angular/core';
import {OauthPassportService} from '../oauth-passport.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private oauthPassportService: OauthPassportService) {
  }

  ngOnInit() {
    this.oauthPassportService.initImplicitFlow();
  }

  login() {
    // this.oauthService.initImplicitFlow();
    alert('Coming soon...');
  }

  loginWithOutLib() {
    // this.oauthPassportService.initImplicitFlow();
  }

}
