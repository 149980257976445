import { Component, OnInit, Directive } from '@angular/core';
import { Location } from '@angular/common';

import { IBaseComponent } from './base-entity';
import { AppService } from './app.service';
import { Observable } from 'rxjs';
import { OauthPassportService } from '../oauth-passport.service';

import { environment } from 'src/environments/environment';


@Directive()
export class BaseComponent implements OnInit , IBaseComponent  {

	// protected loginPath:string = 'auth/login';	
	// public showSpiner : boolean;

	
	constructor(		
		private baseLocation : Location,
		private  baseAppService : AppService,
		private baseOauthPassportService: OauthPassportService,
	) { 				
		
	}

	ngOnInit() {
		
	}	
	
	// protected setTitle(title:string, subTitle:string = null){
	// 	this.baseAppService.setTitle(title, subTitle);
	// }

	public back() : void { this.onBack(); }
	protected onBack() : void { 
		this.baseLocation.back(); 
	}


	public isAuthenticated() {
		return this.baseOauthPassportService.isAuthenticated();
	}

	public getUserIdentity() {
		return this.baseOauthPassportService.getUserIdentity();
	}

	public logOut() {		
		localStorage.removeItem('userinfo');
	    localStorage.removeItem('auth_key');

		this.baseOauthPassportService.logOut();

		window.location.href = environment.logoutUrl;
	}	
	

	// public isApprover() : boolean {
	// 	if (this._user && this._user.permissions){
	// 		for (let permission of this._user.permissions){
	// 			if (permission == 'payment.approve'){
	// 				return true;
	// 			}
	// 		}
	// 	}
	// 	return false;
	// }
	

	public isAdmin(user:any) : boolean {
		if (!user) return false;
		return (user.account && user.account.admin && user.account.admin.roles.indexOf('1,') > -1);
	}

	public isAuditor(user:any) : boolean {
		if (!user) return false;
		return (user.account && user.account.admin && user.account.admin.roles.indexOf('2,') > -1);
	}

	public isApprover(user:any) : boolean {
		if (!user) return false;
		return (user.account && user.account.admin && user.account.admin.roles.indexOf('3,') > -1);
	}


	public queryStringToObject(queryString : string){
		var pairs = queryString.slice(1).split('&');
		
		var result = {};
		pairs.forEach(function(item) {
			let pair = item.split('=');
			result[pair[0]] = decodeURIComponent(pair[1] || '');
		});

		return JSON.parse(JSON.stringify(result));
	}
	


}

